<template>
  <div>
    <van-nav-bar @click-left="onClickLeft">
      <template #title>
        <span style="font-weight: 600; color: #0af">会员中心</span>
      </template>
      <template #left>
        <van-icon name="wap-home-o"
                  size="18" />
      </template>
    </van-nav-bar>
    <br />
    <van-cell-group inset>
      <van-cell value="账号信息" />

      <van-row>
        <van-col span="7">
          <div style="text-align: center; margin-top: 8px">
            <van-image round
                       width="4.5rem"
                       height="4.5rem"
                       src="https://image-1302243118.cos.ap-beijing.myqcloud.com/public/img/Facepalm_Gopher.png" />
          </div>
        </van-col>
        <van-col span="17">
          <van-row class="account-item">
            <van-col><van-icon name="user-o" /> 账号：{{ userInfo.phone }}
            </van-col>
          </van-row>

          <van-row class="account-item">
            <van-col><van-icon name="qr" /> 邀请码：{{
                userInfo.invitationCode
              }}</van-col>
          </van-row>
          <van-row class="account-item">
            <van-col><van-icon name="diamond-o" /> {{ desc }}&nbsp;{{
                userInfo.vipValidateDate
              }}</van-col>
          </van-row>
        </van-col>
      </van-row>

      <van-cell style="background-color: rgb(229, 246, 255)"
                center
                label="入驻星球，享受更多专属权益">
        <template #title>
          <van-icon name="vip-card-o" /> 知识星球年度会员
        </template>
        <template #default>
          <van-button type="info"
                      round
                      size="mini"
                      to="vip">星主权益</van-button>
        </template>
      </van-cell>
    </van-cell-group>

    <br />
    <van-cell-group inset>
      <van-cell value="求职服务" />
      <van-row class="user-links">
        <van-col @click="gotoResume()"
                 span="6">
          <van-icon name="contact" />
          在线简历
        </van-col>
        <van-col @click="tip()"
                 span="6">
          <van-icon name="records" />
          附件简历
        </van-col>
        <van-col  @click="openWishInfo()"
                 span="6">
          <van-icon name="like-o" />
          求职偏好
        </van-col>
        <van-col @click="goto(7)"
                 span="6">
          <van-icon name="hotel-o" />
          Go公司
        </van-col>
      </van-row>
    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-cell value="星球服务-移动端" />
      <van-row class="user-links">
        <van-col @click="goto(0)"
                 span="6">
          <van-icon name="hot-o" />
          Go推荐
        </van-col>
        <van-col @click="goto(1)"
                 span="6">
          <van-icon name="tv-o" />
          Go视界
        </van-col>
        <van-col @click="goto(2)"
                 span="6">
          <van-icon name="records" />
          Go博文
        </van-col>
        <van-col @click="goto(3)"
                 span="6">
          <van-icon name="tosend" />
          Go合集
        </van-col>
      </van-row>
      <van-row class="user-links">
        <van-col @click="goto(4)"
                 span="6">
          <van-icon name="balance-list-o" />
          面试宝典
        </van-col>
        <van-col @click="goto(5)"
                 span="6">
          <van-icon name="diamond-o" />
          Go课程
        </van-col>
        <van-col @click="goto(6)"
                 span="6">
          <van-icon name="sign" />
          Go岗位
        </van-col>
        <van-col @click="goto(7)"
                 span="6">
          <van-icon name="hotel-o" />
          Go公司
        </van-col>
      </van-row>
    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-cell value="星球服务-电脑端" />
      <van-row class="user-links">
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/1.html')"
                 span="6">
          <van-icon name="chart-trending-o" />
          Go路线图
        </van-col>
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/3.html')"
                 span="6">
          <van-icon name="tv-o" />
          Go视界
        </van-col>
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/4.html')"
                 span="6">
          <van-icon name="records" />
          Go博文
        </van-col>
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/5.html')"
                 span="6">
          <van-icon name="smile-comment-o" />
          Go小课
        </van-col>
      </van-row>
      <van-row class="user-links">
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/6.html')"
                 span="6">
          <van-icon name="completed" />
          Go宝典
        </van-col>
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/7.html')"
                 span="6">
          <van-icon name="records" />
          Go小考
        </van-col>
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/8.html')"
                 span="6">
          <van-icon name="sign" />
          优质课程
        </van-col>

        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/9.html')"
                 span="6">
          <van-icon name="certificate" />
          推荐图书
        </van-col>
      </van-row>

      <van-row class="user-links">
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/11.html')
          "
                 span="6">
          <van-icon name="thumb-circle-o" />
          开源项目
        </van-col>
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/12.html')
          "
                 span="6">
          <van-icon name="video-o" />
          视频资源
        </van-col>
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/13.html')
          "
                 span="6">
          <van-icon name="debit-pay" />
          文档资源
        </van-col>

        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/14.html')
          "
                 span="6">
          <van-icon name="browsing-history-o" />
          帮找资源
        </van-col>
      </van-row>

      <van-row class="user-links">
        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/introduce/2.html')"
                 span="6">
          <van-icon name="friends-o" />
          Go友会
        </van-col>
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/15.html')
          "
                 span="6">
          <van-icon name="records" />
          企业题库
        </van-col>
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/16.html')
          "
                 span="6">
          <van-icon name="balance-list-o" />
          面试宝典
        </van-col>

        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/17.html')
          "
                 span="6">
          <van-icon name="logistics" />
          求职面经
        </van-col>
      </van-row>

      <van-row class="user-links">
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/18.html')
          "
                 span="6">
          <van-icon name="exchange" />
          内推互助
        </van-col>
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/19.html')
          "
                 span="6">
          <van-icon name="after-sale" />
          求职助力
        </van-col>
        <van-col @click="
            gotoPC('https://www.golangroadmap.com/docs/introduce/20.html')
          "
                 span="6">
          <van-icon name="peer-pay" />
          内推公司
        </van-col>

        <van-col @click="gotoPC('https://www.golangroadmap.com/docs/goods/')"
                 span="6">
          <van-icon name="shopping-cart-o" />
          Go周边
        </van-col>
      </van-row>
    </van-cell-group>
    <br />

    <van-popup round
               position="bottom"
               :style="{ height: '90%' }"
               v-model="showIframe">
      <iframe :src="iFrameUrl"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen
              sandbox></iframe>
    </van-popup>

    <!--求职期望编辑-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="wishInfoEditShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">我的求职偏好</span>
        </template>
      </van-nav-bar>
      <br />
      <van-form @submit="saveWishInfo">
        <van-field v-model="wishInfo.position"
                   required
                   name="position"
                   label="期望职位"
                   placeholder="期望职位"
                   :rules="[{ required: true, message: '请填写期望职位' }]" />
        <van-field v-model="wishInfo.city"
                   name="city"
                   required
                   label="期望城市"
                   placeholder="期望城市"
                   :rules="[{ required: true, message: '请填写期望城市' }]" />

        <van-field v-model="wishInfo.industry"
                   name="industry"
                   label="期望行业"
                   placeholder="期望行业" />

        <van-field v-model="wishInfo.salary"
                   name="salary"
                   label="期望月薪"
                   placeholder="期望月薪" />

        <van-field name="radio"
                   label="求职状态">
          <template #input>
            <van-radio-group v-model="wishInfo.status"
                             direction="horizontal">
              <van-radio name="正在看机会">正在看机会</van-radio>
              <van-radio name="关注行情">关注行情</van-radio>
              <van-radio name="半年不看机会">半年不看机会</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>
    </van-popup>

  </div>
</template>

<script>
import {
  RadioGroup,
  Radio,
  Form,
  Field,
  Popup,
  Badge,
  Toast,
  Image as VanImage,
  Divider,
  Button,
  NavBar,
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
} from "vant";
const axios = require("axios");
export default {
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Badge.name]: Badge,
    [Toast.name]: Toast,
    VanImage,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data () {
    return {
      userInfo: { vipType: 1, phone: "156****8926", invitationCode: "Go" },
      desc: "正式会员：有效期 2023-9-08",
      isVip: false,
      showIframe: false,
      iFrameUrl: "https://www.golangroadmap.com/docs/introduce/1.html",
      //完善求职期望
      wishInfo: {
        position: "",
        city: "",
        industry: "",
        salary: "",
        status: ""
      },
      wishInfoEditShow: false,
    };
  },
  created () {
    this.getUserInfo();
  },
  methods: {
    saveWishInfo () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/modifyWishInfo",
          this.wishInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("求职期望保存成功!");
            this.wishInfoEditShow = false;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tip () {
      Toast("专区待开放,敬请期待！");
    },
    onClickLeft () {
      this.$router.push({ path: "/home", query: { tab: 0 } });
    },
    gotoPC (val) {
      this.iFrameUrl = val;
      this.showIframe = true;
      // window.open(val, '_blank');
    },
    goto (val) {
      this.$router.push({ path: "/home", query: { tab: val } });
    },
    gotoResume () {
      this.$router.push("resume");
    },
    openWishInfo(){
      axios
        .post("https://api.golangroadmap.com/resume/getWishInfo")
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            if(response.data.data !== undefined){
              this.wishInfo = response.data.data;
            }            
            this.wishInfoEditShow = true;          
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          Toast.fail(error);
        });      
    },
    getUserInfo () {
      axios
        .get("https://api.golangroadmap.com/m/userInfo")
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.userInfo = response.data.data;

            if (this.userInfo.vToken == "232059cb5361a9336ccf1b8c2ba7657a") {
              this.vipImg =
                "https://image-1302243118.cos.ap-beijing.myqcloud.com/public/img/vip.png";
              if (this.userInfo.vipType == 1) {
                //体验vip
                this.desc = "体验星主 :  有效期";
                this.isVip = false;
              } else {
                this.desc = "正式星主 :  有效期";
                this.isVip = true;
              }
            } else {
              if (this.userInfo.vipType == 2) {
                //会员过期
                this.desc = "服务到期 :  有效期";
                this.isVip = false;
              }
            }
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          Toast.fail(error);
        });
    },
  },
};
</script>

<style lang="less">
.account-item {
  padding: 5px 16px;
  font-size: 14px;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
